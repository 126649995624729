<script setup lang="ts">
import type { PropType, WatchStopHandle } from 'vue';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useFloating, offset, flip, shift, arrow } from '@floating-ui/vue';
import type { CmsLink, UiTooltipPlacements, UiTooltipTrigers, UiTooltipAppearances } from '@mop/ui2/types';

defineOptions({
  name: 'Ui2Tooltip',
});

const emit = defineEmits(['click']);

const props = defineProps({
  placement: {
    type: String as PropType<UiTooltipPlacements>,
    default: '',
  },
  id: {
    type: String,
    required: true,
  },
  appearance: {
    type: String as PropType<UiTooltipAppearances>,
    default: 'default',
  },
  trigger: {
    type: String as PropType<UiTooltipTrigers>,
    default: 'mouseover',
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
  link: {
    type: Object as PropType<CmsLink>,
    default: () => ({}),
  },
  noCursor: {
    type: Boolean,
    default: false,
  },
  allowPropagation: {
    type: Boolean,
    default: false,
  },
  allowClick: {
    type: Boolean,
    default: false,
  },
  hideArrow: {
    type: Boolean,
    default: false,
  },
  dataTheme: {
    type: String,
    default: '',
  },
});

// @ts-ignore
const { $resize } = typeof useNuxtApp !== 'undefined' ? useNuxtApp() : {};
const rootRef = ref<HTMLElement>();
const referenceRef = ref<HTMLElement>();
const floatingRef = ref<HTMLElement>();
const arrowRef = ref<HTMLElement>();
const mounted = ref(false);
const isActiveRef = ref(false);
const isOpenByTrigger = ref();
let isUnmounted = false;
let stopWatch: WatchStopHandle;

const {
  floatingStyles: floatingStylesRef,
  update,
  placement: floatingPlacementRef,
  middlewareData: floatingMiddlewareDataRef,
} = useFloating(referenceRef, floatingRef, {
  placement: props.placement,
  middleware: [
    offset(10),
    flip({
      fallbackPlacements: ['top', 'bottom'],
      padding: 10,
    }),
    shift({
      padding: 10,
    }),
    arrow({ element: arrowRef, padding: 5 }),
  ],
});

function show() {
  update();
  isOpenByTrigger.value = true;
}

function hideOnEsc(event: KeyboardEvent) {
  if (event.key === 'Escape') {
    return hide();
  }
}

function hide() {
  isOpenByTrigger.value = false;
}

function handleTouch() {
  isActiveRef.value = !isActiveRef.value;
  isActiveRef.value ? show() : hide();
}

onMounted(() => {
  if (!isUnmounted && props.trigger !== 'none') {
    rootRef.value?.addEventListener(props.trigger, show);
    rootRef.value?.addEventListener('mouseleave', hide);
    rootRef.value?.addEventListener('touchstart', handleTouch);
    referenceRef.value?.addEventListener('blur', hide);
    referenceRef.value?.addEventListener('focus', show);
    document.addEventListener('keydown', hideOnEsc);
    update();
    mounted.value = true;

    // Make sure to rerender, otherwise tooltip could be out of viewport
    setTimeout(() => {
      if (!isUnmounted) {
        update();
      }
    }, 100);

    if ($resize) {
      stopWatch = watch($resize.viewportWidthRef, () => update());
    }
  }
});
onBeforeUnmount(() => {
  isUnmounted = true;

  stopWatch && stopWatch();

  if (props.trigger !== 'none') {
    rootRef.value?.removeEventListener(props.trigger, show);
    rootRef.value?.removeEventListener('mouseleave', hide);
    rootRef.value?.removeEventListener('touchstart', handleTouch);
    referenceRef.value?.removeEventListener('blur', hide);
    referenceRef.value?.removeEventListener('focus', show);
    document.removeEventListener('keydown', hideOnEsc);
  }
});
</script>

<template>
  <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions -->
  <span
    ref="rootRef"
    role="tooltip"
    :class="[
      'ui-tooltip__wrapper',
      `ui-tooltip__wrapper--${appearance}`,
      {
        'ui-tooltip__wrapper--cursor': !noCursor,
      },
    ]"
    @click="($event) => (allowPropagation ? null : $event.stopPropagation())"
  >
    <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions -->
    <div
      :id="id"
      ref="floatingRef"
      :style="floatingStylesRef"
      :class="[
        'ui-tooltip',
        `ui-tooltip--${floatingPlacementRef}`,
        {
          'ui-tooltip--open': mounted && (isOpen || isOpenByTrigger),
          'ui-tooltip--hide-arrow': hideArrow,
        },
      ]"
      role="tooltip"
      :data-theme="dataTheme"
      :aria-hidden="!mounted || (!isOpen && !isOpenByTrigger)"
      @click="($event) => (allowClick ? emit('click') : $event.preventDefault())"
    >
      <Ui2NuxtLink v-bind="link">
        <span
          v-if="!hideArrow"
          ref="arrowRef"
          class="ui-tooltip__arrow"
          :style="{
            left: floatingMiddlewareDataRef?.arrow?.x != null ? `${floatingMiddlewareDataRef?.arrow?.x}px` : '',
            top: floatingMiddlewareDataRef?.arrow?.y != null ? `${floatingMiddlewareDataRef?.arrow?.y}px` : '',
          }"
        />
        <div class="ui-tooltip__content">
          <slot name="contentSlot" />
        </div>
      </Ui2NuxtLink>
    </div>
    <span ref="referenceRef" class="ui-tooltip__slot" tabindex="0" :aria-describedby="id">
      <slot name="previewSlot" />
    </span>
  </span>
</template>

<style lang="scss">
$tooltip-background-color-light: $color-surface-primary;
$tooltip-font-color-light: $color-text-body-primary;

.ui-tooltip__wrapper {
  display: inline-block;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ui-tooltip__wrapper--cursor {
  cursor: help;
}

.ui-tooltip {
  @include v2-z(global, tooltip);

  background: $tooltip-background-color-light;
  color: $tooltip-font-color-light;
  pointer-events: none;
  padding: $space-8 $space-12;
  border-radius: $border-radius-rounded-lg;
  width: auto;
  max-width: 320px;
  transition: opacity $animation-duration-medium $animation-type-standard;
  opacity: 0;
  box-shadow: $shadow-lg;
}

.ui-tooltip--hide-arrow {
  margin-top: 5px;
}

.ui-tooltip__content {
  display: flex;
  flex-direction: column;
  gap: 8px;

  > div {
    @include v2-text-style(xs, highlight);
    text-transform: uppercase;
  }

  > p {
    @include v2-text-style(xs);
    margin: 0;
    padding: 0;
  }
}

.ui-tooltip--open {
  opacity: 1;
  pointer-events: all;
}

.ui-tooltip__arrow {
  position: absolute;
}

.ui-tooltip__arrow,
.ui-tooltip__arrow::before {
  width: 7px;
  height: 7px;
}

.ui-tooltip__arrow::before {
  content: '';
  position: absolute;
  left: 0;

  border-bottom: 1px solid $tooltip-background-color-light;
  border-right: 1px solid $tooltip-background-color-light;
  background: $tooltip-background-color-light;
}

.ui-tooltip--top,
.ui-tooltip--top-start,
.ui-tooltip--top-end {
  .ui-tooltip__arrow {
    bottom: -3px;
  }

  .ui-tooltip__arrow::before {
    transform: rotate(45deg);
  }
}

.ui-tooltip--top-start {
  margin-left: -10px;

  .ui-tooltip__arrow {
    margin-left: 10px;
  }
}

.ui-tooltip--top-end {
  margin-left: 10px;

  .ui-tooltip__arrow {
    margin-left: -10px;
  }
}

.ui-tooltip--bottom {
  .ui-tooltip__arrow {
    top: -4px;
  }

  .ui-tooltip__arrow::before {
    transform: rotate(225deg);
  }
}

.ui-tooltip--left {
  .ui-tooltip__arrow {
    right: -3px;
  }

  .ui-tooltip__arrow::before {
    transform: rotate(-45deg);
  }
}

.ui-tooltip--right {
  .ui-tooltip__arrow {
    left: -4px;
  }

  .ui-tooltip__arrow::before {
    transform: rotate(135deg);
  }
}

.ui-tooltip__slot {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $color-text-action;
  position: relative;
  outline: none;

  &:hover {
    color: $color-text-action-hover;
  }

  &::before {
    position: absolute;
    width: 40px;
    height: 40px;
    content: '';
  }

  &::after {
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    content: '';
    border-radius: $border-radius-rounded-full;
    border: 2px solid $color-border-focus;
    transition: all $animation-duration-medium $animation-type-standard;
    opacity: 0;
  }

  &:focus-visible {
    &::after {
      opacity: 1;
    }
  }
}

.ui-tooltip__wrapper--inverted {
  .ui-tooltip__slot {
    color: $color-text-action-inverted;

    &:hover {
      color: $color-text-action-hover-inverted;
    }

    &::after {
      border-color: $color-border-focus-inverted;
    }
  }
}
</style>
