<script setup lang="ts">
import { h, cloneVNode } from 'vue';
import type { VNodeArrayChildren, WatchStopHandle } from 'vue';
import { asyncTimeout } from '@mop/shared/utils/util';
import Ui2PaginationDots from '../Ui2PaginationDots/Ui2PaginationDots.vue';
import Ui2CarouselSlide from './Ui2CarouselSlide.vue';
import Ui2CarouselScrollBar from './Ui2CarouselScrollBar.vue';
import Ui2CarouselArrow from './Ui2CarouselArrow.vue';
import UiCarousel from './utils/uiCarousel';
import type { UiDotGroupList, UiDotVariant } from '@mop/ui2/types';

defineOptions({
  name: 'Ui2Carousel',
});

const emit = defineEmits(['slide-change', 'mounted', 'before-slide-change']);

const slots = useSlots();

const props = defineProps({
  showArrows: {
    type: Boolean,
    default: false,
  },
  showArrowsMobile: {
    type: Boolean,
    default: false,
  },
  singleScroll: {
    type: Boolean,
    default: false,
  },
  showBullets: {
    type: Boolean,
    default: false,
  },
  bulletList: {
    type: Array as PropType<UiDotGroupList>,
    default: () => [],
  },
  bulletsVariant: {
    type: String as PropType<UiDotVariant>,
    default: 'dark',
  },
  slideFullPage: {
    type: Boolean,
    default: false,
  },
  slideFullHeight: {
    type: Boolean,
    default: false,
  },
  hasInfiniteLoop: {
    type: Boolean,
    default: false,
  },
  autoPlay: {
    type: [Boolean, Number],
    default: false,
  },
  noGap: {
    type: Boolean,
    default: false,
  },
  showPeek: {
    type: Boolean,
    default: false,
  },
  isProductTiles: {
    type: Boolean,
    default: false,
  },
  slideToIndex: {
    type: Number,
    default: 0,
  },
  slideBehavior: {
    type: String as PropType<ScrollBehavior | 'instant'>,
    default: 'smooth',
  },
  maxSlidesCount: {
    type: Number,
    default: 1,
  },
  slideClassname: {
    type: String,
    default: '',
  },
  keepAspectRatio: {
    type: Boolean,
    default: false,
  },
  showScrollbar: {
    type: Boolean,
    default: false,
  },
});

// @ts-ignore
const { $resize } = typeof useNuxtApp !== 'undefined' ? useNuxtApp() : {};
const carouselElementRef: Ref<HTMLElement | null> = ref(null);
let uiCarousel: UiCarousel | null = null;
const showScrollbarRef = ref(false);
const isScrollableRef = ref(false);
const trackWidthRef = ref(0);
const scrollLeftRef = ref(0);
const containerRef = ref<HTMLElement | null>();

let mountedWatcherList: WatchStopHandle[] = [];

function emitSlideChangeEvent(event: CustomEvent) {
  emit('slide-change', event.detail);
}

onMounted(async () => {
  await asyncTimeout(100);

  if (!carouselElementRef.value) {
    return;
  }
  uiCarousel = new UiCarousel(carouselElementRef.value, props.autoPlay as number);
  // @ts-ignore
  carouselElementRef.value.addEventListener('slide-change', emitSlideChangeEvent, false);
  emit('mounted', true);
  uiCarousel.slideToIndex(props.slideToIndex, props.slideBehavior);

  await asyncTimeout(400);

  if (!carouselElementRef.value) {
    return;
  }
  carouselElementRef.value.style.visibility = 'visible';

  mountedWatcherList.push(
    watch(
      containerRef,
      (container) => {
        if (!container) {
          return 0;
        }
        container.addEventListener('scroll', updateScrollPosition);
        updateScrollPosition();
      },
      { immediate: true },
    ),
  );

  if ($resize) {
    // @ts-ignore
    mountedWatcherList.push(watch($resize.viewportWidthRef, updateScrollPosition));
  }

  mountedWatcherList.push(
    watch(
      () => props.slideToIndex,
      (index) => {
        uiCarousel?.slideToIndex(index);
      },
    ),
  );
});

function updateScrollPosition() {
  if (!containerRef.value) {
    return;
  }
  const { scrollWidth, scrollLeft, offsetWidth } = containerRef.value;
  trackWidthRef.value = scrollWidth;
  scrollLeftRef.value = scrollLeft;

  isScrollableRef.value = offsetWidth < scrollWidth;
  showScrollbarRef.value = isScrollableRef.value && props.showScrollbar;
}

onUnmounted(() => {
  mountedWatcherList.forEach((watcher) => watcher());
  mountedWatcherList = [];
  containerRef.value?.removeEventListener('scroll', updateScrollPosition);
  // @ts-ignore
  carouselElementRef.value?.removeEventListener('slide-change', emitSlideChangeEvent, false);
  uiCarousel?.destroy();
  uiCarousel = null;
  carouselElementRef.value = null;
});

const render = () => {
  // @ts-ignore
  const slides = slots.default?.()[0]?.children as VNodeArrayChildren;
  if (!slides?.length) {
    return;
  }

  const items = slides.map((slide) => {
    return h(Ui2CarouselSlide, { class: props.slideClassname }, { default: () => slide });
  });

  if (props.hasInfiniteLoop) {
    // @ts-ignore
    const lastSlide = cloneVNode(slides[slides.length - 1]);
    // @ts-ignore
    const firstSlide = cloneVNode(slides[0]);

    items.unshift(
      h(
        Ui2CarouselSlide,
        {
          class: ['ui-carousel__slide--clone ui-carousel__slide--last', props.slideClassname],
        },
        {
          default: () => lastSlide,
        },
      ),
    );

    items.push(
      h(
        Ui2CarouselSlide,
        {
          class: ['ui-carousel__slide--clone ui-carousel__slide--first', props.slideClassname],
        },
        {
          default: () => firstSlide,
        },
      ),
    );
  }

  return h(
    'div',
    {
      class: [
        'ui-carousel',
        props.hasInfiniteLoop ? 'ui-carousel--loading' : '',
        props.maxSlidesCount > 1 ? `ui-carousel--show${props.maxSlidesCount}` : '',
        {
          // Force set start class based on props, as initArrows() failed to set class name for element. Remove after fixing initArrows()
          'ui-carousel--start': props.slideToIndex === 0,
          'ui-carousel--bullets': props.showBullets,
          'ui-carousel--scrollable': isScrollableRef.value,
          'ui-carousel--infinite-loop': props.hasInfiniteLoop,
          'ui-carousel--single-scroll': props.singleScroll,
          'ui-carousel--auto-play': props.autoPlay,
          'ui-carousel--slide-full-page': props.slideFullPage,
          'ui-carousel--slide-full-height': props.slideFullHeight,
          'ui-carousel--keep-ratio': props.keepAspectRatio,
          'ui-carousel--nogap': props.noGap,
          'ui-carousel--peek': props.showPeek,
          'ui-carousel--product-tiles': props.isProductTiles,
          'ui-carousel--arrows-mobile': props.showArrows && props.showArrowsMobile,
          'ui-carousel--instant-behavior': props.slideBehavior === 'instant',
        },
      ],
      'data-current-thumbnail-index': '0',
    },
    {
      default: () => [
        h(
          'ul',
          { class: 'ui-carousel__container', ref: containerRef, 'aria-label': 'Gallery' },
          { default: () => items },
        ),
        h(Ui2CarouselArrow, {
          class: 'ui-carousel__arrow ui-carousel__arrow--prev',
          direction: 'prev',
          size: 'lg',
          hidden: !props.showArrows,
        }),
        h(Ui2CarouselArrow, {
          class: 'ui-carousel__arrow ui-carousel__arrow--next',
          direction: 'next',
          size: 'lg',
          hidden: !props.showArrows,
        }),
        h(
          'div',
          { class: 'ui-carousel__bottom' },
          {
            default: () => [
              slots.aboveBullets
                ? h('div', null, { default: () => slots?.aboveBullets && slots.aboveBullets() })
                : null,
              props.showBullets
                ? h(Ui2PaginationDots, {
                    class: 'ui-carousel__bullets',
                    variant: props.bulletsVariant,
                    list: props.bulletList,
                  })
                : null,
              slots.bellowBullets
                ? h('div', null, { default: () => slots?.bellowBullets && slots.bellowBullets() })
                : null,
            ],
          },
        ),
        showScrollbarRef.value
          ? h(Ui2CarouselScrollBar, {
              key: `scrollbar-${showScrollbarRef.value}`,
              class: 'ui-carousel__scrollbar',
              scrollWidth: trackWidthRef.value,
              scrollLeft: scrollLeftRef.value,
              'onUpdate:positionLeft': (value: number) => {
                if (uiCarousel) {
                  uiCarousel.setScrollLeft(value);
                }
              },
            })
          : null,
      ],
    },
  );
};

defineExpose({
  carouselElementRef,
});
</script>

<template>
  <render ref="carouselElementRef" />
</template>

<style lang="scss">
.ui-carousel {
  --ui-carousel-item-width: 100%;
  --ui-carousel-item-gap: #{$space-8};
  --ui-carousel-item-peek-width: 0px;
  --ui-carousel-item-count: 1;

  transition: opacity $animation-duration-medium $animation-type-standard;
  position: relative;
  display: block;
  width: 100%;
  scrollbar-width: none; /* Firefox */
  scrollbar-color: $middle-grey transparent; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
    height: 0;
    width: 0;
  }

  &:not(.ui-carousel--scrollable) {
    .ui-carousel__arrow {
      display: none;
    }
  }
}

.ui-carousel,
.ui-carousel::after,
.ui-carousel::before {
  box-sizing: border-box;
}

.ui-carousel__container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  display: flex;
  align-items: top;
  gap: var(--ui-carousel-item-gap);
  height: 100%;
  min-height: 300px;
  list-style: none;
  margin: 0;
  padding: 0;
  scrollbar-width: none;
  scrollbar-color: $middle-grey transparent;
  background-clip: padding-box;
  width: var(--ui-carousel-width, auto);

  &::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
    height: 0;
    width: 0;
  }

  @include v2-apply-upto(mobile) {
    min-height: 200px;
  }
}

.ui-carousel-no-snap {
  /* stylelint-disable declaration-no-important */
  scroll-snap-type: initial !important;
  /* stylelint-enable declaration-no-important */
}

.ui-carousel::-webkit-scrollbar,
.ui-carousel__container::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
  height: 0;
  width: 0;
}

.ui-carousel--keep-ratio {
  .ui-carousel__container {
    min-height: initial;
  }

  .ui-mop-cms-slider-slide__background {
    .img,
    .ui-mop-cms-media-wrapper {
      height: initial;
    }
  }
}

.ui-carousel--loading {
  opacity: 0;
}

.ui-carousel--slide-full-page {
  .ui-carousel__container {
    scroll-snap-type: x mandatory !important;
  }
}

.ui-carousel--slide-full-height {
  .ui-carousel__container {
    height: auto;
  }
}

.ui-carousel--nogap {
  --ui-carousel-item-gap: 0px;
}

.ui-carousel--peek {
  --ui-carousel-item-peek-width: 150px;

  @include v2-apply-upto(desktop) {
    --ui-carousel-item-peek-width: 110px;
  }

  @include v2-apply-upto(tablet) {
    --ui-carousel-item-peek-width: 90px;
  }

  @include v2-apply-upto(mobile) {
    --ui-carousel-item-peek-width: 70px;
  }
}

.ui-carousel--show2 {
  --ui-carousel-item-count: 2;
}

.ui-carousel--show3 {
  --ui-carousel-item-count: 3;

  @include v2-apply-upto(big) {
    --ui-carousel-item-count: 2;
  }

  @include v2-apply-upto(desktop) {
    --ui-carousel-item-count: 1;
  }
}

.ui-carousel--show4 {
  --ui-carousel-item-count: 4;

  @include v2-apply-upto(tablet) {
    --ui-carousel-item-count: 3;
  }

  @include v2-apply-upto(mobile) {
    --ui-carousel-item-count: 2;
  }
}

.ui-carousel--show5 {
  --ui-carousel-item-count: 5;

  @include v2-apply-upto(desktop) {
    --ui-carousel-item-count: 4;
  }

  @include v2-apply-upto(tablet) {
    --ui-carousel-item-count: 3;
  }

  @include v2-apply-upto(mobile) {
    --ui-carousel-item-count: 2;
  }

  .ui-carousel__container {
    min-height: auto;
  }
}

.ui-carousel--no-smooth-scroll {
  .ui-carousel__container {
    scroll-behavior: auto;
  }
}

.ui-carousel__slide--first {
  display: none;
}

.ui-carousel--initialized {
  .ui-carousel__slide--first {
    display: block;
  }
}

.ui-carousel__arrow,
.ui-carousel__bottom {
  @include v2-z(global, content);
}

.ui-carousel__bottom {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
}

.ui-carousel__bullets {
  -webkit-touch-callout: none;
  user-select: none;
  margin-bottom: $space-12;
}

.ui-carousel__bullet {
  padding: $space10 3px;
  margin: 0;
  border: none;
  cursor: pointer;
  width: 10px;
  height: 5px;
  position: relative;
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: currentColor;

  @include hover {
    &::after {
      transform: scale(1.5);
      opacity: 1;
    }
  }

  &::after {
    content: '';
    position: absolute;
    height: 5px;
    width: 5px;
    left: 2px;
    background-color: currentColor;
    opacity: 0.3;
    border-radius: 50%;
    transition: all 0.25s ease;
  }
}

.ui-carousel__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  @include v2-apply-upto(mobile) {
    display: none;
  }
}

.ui-carousel--product-tiles {
  .ui-carousel__arrow {
    top: calc(50% - 50px); // tile product-info height
  }
}

.ui-carousel--arrows-mobile {
  .ui-carousel__arrow {
    @include v2-apply-upto(mobile) {
      display: flex;
    }
  }
}

.ui-carousel--instant-behavior {
  visibility: hidden;
}

.ui-carousel__arrow--next {
  right: $space-24;

  @include v2-apply-upto(mobile) {
    right: $space-16;
  }
}

.ui-carousel__arrow--prev {
  left: $space-24;

  @include v2-apply-upto(mobile) {
    left: $space-16;
  }
}

.ui-carousel--start {
  .ui-carousel__arrow--prev {
    cursor: not-allowed;
    opacity: 0.4;
  }
}

.ui-carousel--end {
  .ui-carousel__arrow--next {
    cursor: not-allowed;
    opacity: 0.4;
  }
}

.ui-carousel--single {
  .ui-carousel__arrow,
  .ui-carousel__bullets {
    display: none;
  }
}

.ui-carousel__slide {
  --ui-carousel-item-gap-totalwidth: calc(var(--ui-carousel-item-gap) * (var(--ui-carousel-item-count) - 1));
  --ui-carousel-item-width: calc(
    (100% - var(--ui-carousel-item-peek-width) - var(--ui-carousel-item-gap-totalwidth)) / var(--ui-carousel-item-count)
  );

  scroll-snap-align: center;
  position: relative;
  height: 100%;
  width: var(--ui-carousel-item-width-override, var(--ui-carousel-item-width));
  min-width: var(--ui-carousel-item-width-override, var(--ui-carousel-item-width));
  flex-basis: var(--ui-carousel-item-width-override, var(--ui-carousel-item-width));
  overflow: hidden;
}

.ui-carousel__scrollbar {
  margin-top: $space-24;
}

.ui-carousel--single-scroll {
  .ui-carousel__container {
    scroll-snap-type: x mandatory !important;
  }

  .ui-carousel__slide {
    scroll-snap-stop: always;
  }
}
</style>
